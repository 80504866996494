<template>
  <!-- 员工中心 -->
  <AppBar>
    <div class="top_box">
      <el-form :model="queryParams" :inline="true" ref="queryParams" label-width="90px">
        <el-form-item label="代码：" prop="codeEquals">
          <el-input
            placeholder="请输入代码"
            clearable
            size="small"
            v-model="queryParams.codeEquals"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="名称" prop="nameLike">
          <el-input
            placeholder="请输入名称"
            clearable
            size="small"
            v-model="queryParams.nameLike"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="body_box">
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="row">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="代码" align="center" prop="code" />
        <el-table-column label="名称" align="center" prop="name" />
        <el-table-column label="是否启用" align="center" prop="enabled">
          <template slot-scope="scope">{{
            scope.row.enabled ? "启用" : "禁用"
          }}</template>
        </el-table-column>
        <el-table-column label="上级组织" align="center" prop="namePath" />
        <el-table-column label="简称" align="center" prop="shortName" />
        <el-table-column label="电话" align="center" prop="phone" />
        <el-table-column label="地址" align="center" prop="address" />
        <el-table-column label="联系人" align="center" prop="contact" />
        <el-table-column
          label="最后操作信息"
          align="center"
          prop="lastModifyInfo"
          width="200"
        >
          <template slot-scope="scope">
            [{{ scope.row.lastModifyInfo.operName }}]{{
              scope.row.lastModifyInfo.id
            }}
            {{ scope.row.lastModifyInfo.time }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-delete"
              plain
              size="mini"
              @click="enable(scope.row)"
              >启用</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              plain
              size="mini"
              @click="disable(scope.row)"
              >禁用</el-button
            >
            <el-button
              plain
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="edit(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="total"
        :page.sync="queryParams.pageNumber"
        :limit.sync="queryParams.pageSize"
        @pagination="getMore"
        style="display: flex;align-items: center;justify-content: center;"
      />
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="open"
      width="500px"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="代码：" prop="code">
          <el-input v-model="form.code" class="width240" placeholder="请输入代码" />
        </el-form-item>
        <el-form-item label="名称" prop="name" >
          <el-input v-model="form.name" class="width240" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="简称" prop="shortName">
          <el-input v-model="form.shortName" class="width240" placeholder="请输入简称" />
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="form.phone" class="width240" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="form.address" class="width240" placeholder="请输入地址" />
        </el-form-item>
        <el-form-item label="联系人" prop="contact">
          <el-input v-model="form.contact" class="width240" placeholder="请输入手机" />
        </el-form-item>
        <el-form-item label="是否叶子节点" prop="isLeaf">
          <el-select v-model="form.isLeaf" class="width240" style="width: 100%" placeholder="是否叶子节点">
            <el-option
              v-for="item in otherLogins"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级组织" prop="upper" >
          <el-select
            v-model="form.upper"
            class="width240"
            style="width: 100%"
            :disabled="form.uuid"
            placeholder="请选择"
          >
            <el-option
              v-for="group in orgTreeList"
              :key="group.id"
              :label="group.text"
              :value="group.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组织路径" v-if="form.uuid" prop="namePath">
          <el-input v-model="form.namePath" class="width240" :disabled="form.uuid" />
        </el-form-item>
        <el-form-item label="组织形态" prop="belongOrg">
          <el-select v-model="form.belongOrg"  style="width: 100%" class="width240" placeholder="请选择">
            <el-option
              v-for="group in StoreTypes"
              :key="group.id"
              :label="group.text"
              :value="group.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="创建信息" prop="createInfoDetail" v-if="id">
          <div>{{ form.createInfoDetail }}</div>
        </el-form-item>
        <el-form-item label="最后修改信息" prop="lastModifyInfoDetail" v-if="id">
          <div>{{ form.lastModifyInfoDetail }}</div>
        </el-form-item> -->
        <!-- <el-form-item label="附件" style="width:100%">
          <el-upload
            ref="uploadBtn"
            class="upload-demo"
            multiple
            :file-list="fileList"
            :action="
              actionUrl + '?entityType=organization&entityUuid=' + entityUuid
            "
            :before-upload="beforeExcelUpload"
            :on-success="handleUploadSuccess"
            :on-remove="handleRemove"
          >
            <el-button type="primary">上传文件</el-button>
          </el-upload>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </AppBar>
</template>

<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: white;
  > i {
    width: 360px;
  }
  .box {
    position: relative;
    width: 360px;
    height: 170px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;

    .main {
      position: relative;
      width: 86%;
      height: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .one {
        position: relative;
        height: 100%;
        width: 36%;

        .el-image {
          height: 100%;
          width: 100%;
          border-radius: 4px;
        }
      }
      .two {
        margin-left: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        > p {
          color: #afafaf;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 120px;

          > span {
            color: #696c70;
          }
        }
      }
    }
  }
}
</style>

<script>
import pagination from "@/components/Pagination";
import AppBar from "@/components/AppBar";
import Api from "@/api/index";
export default {
  data() {
    const validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("名称不能为空"));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("代码不能为空"));
      } else if(!value.trim().length >= 4){
        callback(new Error("code 长度必须大于等于"))
      } else {
        callback();
      }
    };
    return {
      total: 10,
      entityUuid: "",
      fileList: [],
      detailObj: {},
      disableFlag: false,
      id: 0,
      open: false,
      rules:{
        code: [{ required: true, validator: validateCode, trigger: "blur" }],
        name: [{ required: true, validator: validateName, trigger: "blur" }],
        upper: [{ required: true, message: '请选择上级组织', trigger: "blur" }],
        isLeaf: [{ required: true, message: '请选择是否子节点', trigger: "blur" }],
      },
      otherLogins: [
        {
          name: "是",
          value: true
        },
        {
          name: "否",
          value: false
        }
      ], // 是否叶子节点
      isShowPop: false,
      dataStaffs: [],
      userData: {},
      currentPage: 1,
      total: 0,
      row: [],
      queryParams: {
        pageNumber: 1,
        pageSize: 10,
        nameLike: "", // 名称类似于
        codeEquals: "" // 代码
      },
      form: {
        code: "",
        name: "",
        shortName: "",
        phone: "",
        address: "",
        isLeaf: "",
        contact: "",
        upper: "",
      },
      orgTreeList: [],
      StoreTypes: [], // 组织形态数据
    };
  },
  methods: {
    // 分页
    getMore(pagination) {
      if (pagination.page === this.queryParams.pageNumberber) {
        this.queryParams.pageSize = pagination.limit;
      } else {
        this.queryParams.pageNumberber = pagination.page;
      }
      this.queryList();
    },
    handleAdd() {
      this.open = true;
      this.form = {
        code: "",
        name: "",
        shortName: "",
        phone: "",
        address: "",
        isLeaf: "",
        contact: "",
        upper: ""
      };
      this.detailObj = {};
    },
    cancel() {
      this.open = false;
    },
    //时间戳转化日期
    formatDate(str) {
      if(!str) {
        return str
      }
      let date = new Date(str); //获取系统时间
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      let day = date.getDate();
      day = day < 10 ? "0" + day : day;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return year + "-" + month + "-" + day + " " + h + ":" + m + ":" + s;
    },
    /* 上传前的校验，文件大小不能大于1M */
    beforeExcelUpload(file) {
      const isLt = file.size / 1024 / 1024 < 1;
      if (!isLt) {
        this.$message.error("上传大小不能超过 1M!");
      }
      return isLt;
    },
    //上传成功后
    handleUploadSuccess(response, file, fileList) {
      console.log(response);
      if (response) {
        this.$message.success("上传成功");
      } else {
        this.$message.error("上传失败");
      }
    },
    //移除上传
    handleRemove(file, fileList) {
      Api.remove("organization", this.entityUuid, file.name).then((res) => {
        this.$message.success("移除成功");
      });
    },
    // 所属组织
    getOrgTreeList() {
      Api.getOrgTree().then(res => {
        var dataList = res.data.data.children;
        var data = res.data.data;
        this.orgTreeList.push(data);
        dataList.forEach(val => {
          this.orgTreeList.push(val);
        });
        console.log(this.orgTreeList);
      });
    },
    // 获取组织形态
    getStoreTypesList() {
      console.log("进入了");
      Api.getStoreTypes(this.allowEmpty).then(res => {
        console.log(res);
        this.StoreTypes = res.data.data;
      });
    },
    // 新建数据
    addData() {
      var upperObj = {};
      if (this.form.upper !== "") {
        upperObj.uuid = this.form.upper;
      }
      Api.addUserObj(
        this.form.code,
        this.form.name,
        this.form.shortName,
        this.form.phone,
        this.form.address,
        this.form.isLeaf,
        this.form.contact,
        upperObj
      )
        .then(res => {
          if (res.data.success) {
            this.$message.success("新建成功");
            this.open = false;
            this.getOrgList();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.data.message);
        });
    },
    // 修改数据
    editData() {
      Api.editOrg(
        this.detailObj.uuid,
        this.detailObj.version,
        this.detailObj.createInfo,
        this.detailObj.lastModifyInfo,
        this.detailObj.enterprise,
        this.detailObj.attachmentId,
        this.detailObj.enabled,
        this.detailObj.level,
        this.detailObj.path,
        this.detailObj.namePath,
        this.detailObj.authentication,
        this.detailObj.storeType,
        this.detailObj.orgType,
        this.form.code,
        this.form.name,
        this.form.shortName,
        this.form.phone,
        this.form.address,
        this.form.isLeaf,
        this.form.contact,
        this.detailObj.upper
      )
        .then(res => {
          if (res.data.success) {
            this.$message.success("编辑成功");
            this.open = false;
            this.getOrgList();
          } else {
            this.$message.error("编辑失败");
          }
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.data.message);
        });
    },
    // 新建或修改员工数据保存
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.form.uuid) {
            this.editData();
          } else {
            this.addData();
          }
        } else {
          return false;
        }
      });
    },
    disable(row) {
      Api.organizationDisable(row.uuid).then(res => {
        if (res && res.data && res.data.success) {
          this.getOrgList();
          this.$message.success('禁用成功')
        } else {
          this.$message.error(res.data.message)
        }
      });
    },
    enable(row) {
      Api.organizationEnable(row.uuid).then(res => {
        if (res && res.data && res.data.success) {
          this.getOrgList();
          this.$message.success('启用成功')
        } else {
          this.$message.error(res.data.message)
        }
      });
    },
    edit(row) {
      this.form = {
        code: "",
        name: "",
        shortName: "",
        phone: "",
        address: "",
        isLeaf: "",
        contact: "",
        upper: ""
      };
      this.detailObj = {};
      Api.organizationGet(row.uuid).then(res => {
       if (res.data.success) {
          this.detailObj = res.data.data;
          //   -------  上传需要的uuid -------
          this.entityUuid = res.data.data.uuid;
          //   -------  上传需要的uuid -------
          this.form.uuid = res.data.data.uuid;
          this.form.code = res.data.data.code;
          this.form.name = res.data.data.name;
          this.form.shortName = res.data.data.shortName;
          this.form.phone = res.data.data.phone;
          this.form.address = res.data.data.address;
          this.form.isLeaf = res.data.data.isLeaf;
          this.form.contact = res.data.data.contact;
          this.form.upper = res.data.data.upper ? res.data.data.upper.uuid : '';
          this.form.namePath = res.data.data.namePath;

          this.form.createInfoDetail =
            `[${res.data.data.creator}] ${res.data.data.creatorId} ` +
            res.data.data.created;
          this.form.lastModifyInfoDetail =
            `[${res.data.data.lastModifier}] ${res.data.data.lastModifierId} ` +
            res.data.data.lastModified;
          // this.getFileList();
          this.open = true;
        }
      })
    },
    // 获取上传数据
    getFileList() {
      Api.getFile("organization", this.entityUuid).then((res) => {
        console.log(res);
        if (res && res.length > 0) {
          res.forEach((val) => {
            console.log(val);
            this.fileList.push({
              name: val.fileName,
              url: val.fileUrl,
            });
          });
        }
      });
    },
    handleQuery() {
      this.getOrgList();
    },
    resetQuery() {
      this.$refs.queryParams.resetFields();
      this.getOrgList();
    },
    // 获取列表数据
    getOrgList() {
      Api.organizationQuery(this.queryParams).then(res => {
        if (res.data.success) {
          this.row = res.data.data ? res.data.data.records : [];
        }
      });
    }
  },
  computed: {
    actionUrl() {
      return process.env.BASE_API + "/attachment/upload.thor";
    }
  },
  mounted() {
    this.getOrgList();
    this.getOrgTreeList();
    this.getStoreTypesList();
    // console.log(this.dataStaffs);
  },
  components: {
    AppBar,
    pagination
  }
};
</script>
